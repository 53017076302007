import TweenMax from 'gsap/src/uncompressed/TweenMax'
import { Power3 } from 'gsap/TweenLite'
import ScrollToPlugin from 'gsap/src/uncompressed/plugins/ScrollToPlugin'

(($) => {
  var $window = $(window)

  $('a[href*="#"]').on('click', function () {
    var href = $(this.hash).selector
    var topY = $(href).offset() && $(href).offset().top

    TweenMax.to($window, 1, {
      scrollTop: {
        y: topY,
        autoKill: true
      },
      ease: Power3.easeOut
    })
    return false
  })

})(jQuery)